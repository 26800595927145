import styled from 'styled-components';
import { BrandingHexColors } from '../GlobalStyle';

export const LoginLayoutContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;

    .login-form__container {
        padding: 2rem;
        background: #ffffff;
        border: 1px solid #ddd;

        button {
            margin: 2rem 0;
            width: 100%;
            height: 55px;
            font-size: 20px;
            background-color: ${BrandingHexColors.BUILDERS_BLUE};
        }
    }

    img {
        display: block;
        margin: 2rem auto;
    }
`;
