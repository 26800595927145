import { AxiosRequestConfig } from 'axios';
import { msalInstance } from '../authConfig';

function createBearerToken(accessToken: string): string {
    return `Bearer ${accessToken}`;
}

export const requestWithAuthorization = async (
    request: AxiosRequestConfig<any>,
    scopes: string[]
): Promise<AxiosRequestConfig<any>> => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts?.length > 0) {
        const accessTokenRequest = {
            scopes,
            account: accounts[0]
        };

        const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
        // Acquire token silent success
        const accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        if (request.headers) {
            request.headers.Authorization = createBearerToken(accessToken);
        }
        return await Promise.resolve(request);
    }

    return Promise.resolve(request);
};

export const requestWithAuthorizationFromToken = (
    request: AxiosRequestConfig<any>,
    tokenString: string | null
): AxiosRequestConfig<any> => {
    if (tokenString) {
        const token = createBearerToken(tokenString);

        if (request.headers) {
            request.headers.Authorization = token;
        } else {
            request.headers = {
                Authorization: token
            };
        }
    }
    return request;
};
