import { orderBy } from 'lodash';
import userApi, { RoleInput } from '../api/userApi';
import { makeAutoObservable, observable } from 'mobx';
import { Roles } from '../models/Roles';

export class RoleStore {
    roles: Roles[] = [];
    role: Roles = new Roles();
    isLoading: boolean = true;
    @observable public error: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    fetchAllRoles = async () => {
        try {
            this.setIsLoading(true);
            const r = await userApi.roles.getAll();
            this.setRoles(orderBy(r.data, [(roles) => roles.name.toLowerCase()]));
        } catch (error) {
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    fetchRoleById = async (roleId: string) => {
        try {
            this.setIsLoading(true);
            const response = await userApi.roles.getRole(roleId);
            this.setRole(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    createRole = async (role: RoleInput) => {
        try {
            this.setIsLoading(true);
            const response = await userApi.roles.create(role);
            this.setRole(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    updateRole = async (roleId: string, role: RoleInput) => {
        try {
            this.setIsLoading(true);
            const response = await userApi.roles.update(roleId, role);
            this.setRole(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    deleteRole = async (roleId: string) => {
        try {
            await userApi.roles.delete(roleId);
        } catch (error) {
            console.log(error);
            this.error = 'Failed to remove Role';
        }
    };

    setRoles(value: Roles[]) {
        this.roles = value;
    }

    setRole(value: Roles) {
        this.role = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }
}
