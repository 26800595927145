import { AxiosRequestConfig } from 'axios';
import { getAzureAuthUserScope, getUserAPI, isAzureAuthEnabled } from '../../Environment';
import { requestWithAuthorization, requestWithAuthorizationFromToken } from '../../utils/AuthUtils';
import { TokenUtils } from '../../shared/utils/TokenUtils';
import { Transport } from '../../shared/transport/Transport';

class UserApiProvider extends Transport {
    constructor() {
        super(getUserAPI());
        this.setupInterceptors();
    }

    override onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        if (isAzureAuthEnabled()) {
            return requestWithAuthorization(config, [getAzureAuthUserScope()]);
        }
        return requestWithAuthorizationFromToken(config, TokenUtils.getJwtTokenString());
    };
}

export const userApiProvider = new UserApiProvider();
