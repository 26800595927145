import { createGlobalStyle } from 'styled-components';

export enum BrandingHexColors {
    BUILDERS_BLUE = '#00205B',
    PARADIGM_GREEN = '#159A49'
}

export const GlobalStyle = createGlobalStyle`
   * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    ::before, ::after {
        box-sizing: inherit;
    }
    
    html, body {
        font-family: Montserrat;
        background-color: #f7f7f7;
        margin: 0;
    }
`;
