export function getUserAPI() {
    let USER_API = '___ENV-VAR-USER_API___';
    if (process.env.REACT_APP_USER_API && isNotProduction()) {
        USER_API = process.env.REACT_APP_USER_API;
    }
    return USER_API;
}

export function getBuilderProjectAPI() {
    let BUILDER_PROJECT_API = '___ENV-VAR-BUILDER_PROJECT_API___';
    if (process.env.REACT_APP_BUILDER_PROJECT_API && isNotProduction()) {
        BUILDER_PROJECT_API = process.env.REACT_APP_BUILDER_PROJECT_API;
    }
    return BUILDER_PROJECT_API;
}

export function isNotProduction() {
    return process.env.NODE_ENV !== 'production';
}

export function getAzureAuthClientId() {
    let AZURE_AUTH_CLIENT_ID = '___ENV-VAR-AZURE_AUTH_CLIENT_ID___';

    if (process.env.REACT_APP_AZURE_AUTH_CLIENT_ID && isNotProduction()) {
        AZURE_AUTH_CLIENT_ID = process.env.REACT_APP_AZURE_AUTH_CLIENT_ID;
    }
    return AZURE_AUTH_CLIENT_ID;
}

export function getAzureAuthDomain() {
    let AZURE_AUTH_DOMAIN = '___ENV-VAR-AZURE_AUTH_DOMAIN___';

    if (process.env.REACT_APP_AZURE_AUTH_DOMAIN && isNotProduction()) {
        AZURE_AUTH_DOMAIN = process.env.REACT_APP_AZURE_AUTH_DOMAIN;
    }
    return AZURE_AUTH_DOMAIN;
}

export function getAzureAuthInstance() {
    let AZURE_AUTH_INSTANCE = '___ENV-VAR-AZURE_AUTH_INSTANCE___';

    if (process.env.REACT_APP_AZURE_AUTH_INSTANCE && isNotProduction()) {
        AZURE_AUTH_INSTANCE = process.env.REACT_APP_AZURE_AUTH_INSTANCE;
    }
    return AZURE_AUTH_INSTANCE;
}

export function getAzureAuthSignUpSignInPolicy() {
    let AZURE_AUTH_SIGNUP_SIGNIN_POLICY = '___ENV-VAR-AZURE_AUTH_SIGNUP_SIGNIN_POLICY___';

    if (process.env.REACT_APP_AZURE_AUTH_SIGNUP_SIGNIN_POLICY && isNotProduction()) {
        AZURE_AUTH_SIGNUP_SIGNIN_POLICY = process.env.REACT_APP_AZURE_AUTH_SIGNUP_SIGNIN_POLICY;
    }
    return AZURE_AUTH_SIGNUP_SIGNIN_POLICY;
}

export function getAzureAuthUserScope() {
    let AZURE_AUTH_USER_SCOPE = '___ENV-VAR-AZURE_AUTH_USER_SCOPE___';

    if (process.env.REACT_APP_AZURE_AUTH_USER_SCOPE && isNotProduction()) {
        AZURE_AUTH_USER_SCOPE = process.env.REACT_APP_AZURE_AUTH_USER_SCOPE;
    }
    return AZURE_AUTH_USER_SCOPE;
}

export function getAzureAuthBuilderProjectScope() {
    let AZURE_AUTH_BUILDER_PROJECT_SCOPE = '___ENV-VAR-AZURE_AUTH_BUILDER_PROJECT_SCOPE___';

    if (process.env.REACT_APP_AZURE_AUTH_BUILDER_PROJECT_SCOPE && isNotProduction()) {
        AZURE_AUTH_BUILDER_PROJECT_SCOPE = process.env.REACT_APP_AZURE_AUTH_BUILDER_PROJECT_SCOPE;
    }
    return AZURE_AUTH_BUILDER_PROJECT_SCOPE;
}

export function getAzureAuthBrandName() {
    let AZURE_AUTH_BRAND_NAME = '___ENV-VAR-AZURE_AUTH_BRAND_NAME___';

    if (process.env.REACT_APP_AZURE_AUTH_BRAND_NAME && isNotProduction()) {
        AZURE_AUTH_BRAND_NAME = process.env.REACT_APP_AZURE_AUTH_BRAND_NAME;
    }
    return AZURE_AUTH_BRAND_NAME;
}

export function isAzureAuthEnabled() {
    let AZURE_AUTH_ENABLED = '___ENV-VAR-AZURE_AUTH_ENABLED___';

    if (process.env.REACT_APP_AZURE_AUTH_ENABLED && isNotProduction()) {
        AZURE_AUTH_ENABLED = process.env.REACT_APP_AZURE_AUTH_ENABLED;
    }

    return AZURE_AUTH_ENABLED?.toLowerCase() === 'true';
}
