import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Spinner from '../../shared/styleguide/Spinner';

const RolesForm = React.lazy(() => import('./RoleForm'));
const RolesList = React.lazy(() => import('./RolesList'));
const RoleManagementRoutes = () => {
    return (
        <React.Fragment>
            <Suspense fallback={<Spinner />}>
                <Routes>
                    <Route path="/*" element={<RolesList />} />
                    <Route path={'/roles'} element={<RolesList />} />
                    <Route path={'/roles/:roleId'} element={<RolesForm />} />
                    <Route path={'/roles/add'} element={<RolesForm />} />
                </Routes>
            </Suspense>
        </React.Fragment>
    );
};

export default RoleManagementRoutes;
