import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Nav } from './TabNavigation.style';

interface TabNavItem {
    name: string;
    label: string;
    path: string;
    isHidden?: boolean;
}

interface Props {
    items: TabNavItem[];
}

const TabNavigation: React.FC<Props> = ({ items }) => {
    return (
        <Container>
            <Nav>
                {items.map((item, index) => {
                    if (item.isHidden) return null;

                    return (
                        <li key={index}>
                            <NavLink to={item.path}>{item.label}</NavLink>
                        </li>
                    );
                })}
            </Nav>
        </Container>
    );
};

export default TabNavigation;
