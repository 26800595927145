import { userApiProvider } from './providers/userApiProvider';

const AUTH_END_POINT = 'auth';

const auth = {
    login: (email: string, password: string) => {
        const userCredentials = {
            username: email,
            password: password
        };
        return userApiProvider.post(`/${AUTH_END_POINT}/login`, userCredentials);
    }
};

const authApi = {
    auth
};

export default authApi;
