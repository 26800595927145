import styled from 'styled-components';

export const HeaderContainer = styled.div<{ height: number }>`
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;
    font-weight: 500;
    filter: drop-shadow(rgb(186, 186, 186) 1px 0px 2px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: ${(p) => p.height}px;
    right: 0;
    position: fixed;
    top: 0;
    z-index: 99;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-logo {
        margin: 1rem;
        width: 33%;

        img {
            width: 150px;
            background-size: cover;
        }
    }

    .header-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 33%;
        margin-right: 1rem;
    }

    span {
        float: left;
        padding: 5px;
        color: #202834;
        font-size: 16px;
    }

    button {
        float: left;
        font-size: 16px;
        margin: 0 5px;
        padding: 5px;
    }
`;
