import { HeaderContainer } from './Header.style';
import Logo from '../assets/images/HomeConfigureLogo.png';
import { useStores } from '../../../hooks/useStores';
import Button from '../Button';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

type HeaderProps = {
    height: number;
    logo?: string;
};

function Header({ height, logo = Logo }: HeaderProps) {
    const { authStore } = useStores();
    const navigate = useNavigate();

    return (
        <HeaderContainer height={height}>
            <div className="header-logo">
                <img src={logo} alt="" />
            </div>
            <div className="header-right">
                <span>Hi, {authStore.loggedInUser?.username.split('@')[0]}</span>
                <Button onClick={() => authStore.logout(() => navigate('/'))}>Logout</Button>
            </div>
        </HeaderContainer>
    );
}

export default observer(Header);
