import { useNavigate } from 'react-router';
import styled from 'styled-components';
import Button from '../shared/styleguide/Button';

type Props = {
    title?: string;
    message?: string;
};

function NotFound({
    title = '404 - Page Not Found',
    message = 'The page you were looking for could not be found'
}: Props) {
    const navigate = useNavigate();
    return (
        <Container>
            <h1>{title}</h1>
            <p>{message}</p>
            <br />
            <Button onClick={() => navigate('/user')}>Back</Button>
        </Container>
    );
}

export default NotFound;

const Container = styled.div`
    text-align: center;
    margin-top: 16px;
    padding: 12em 0;
    color: #353535;

    h1 {
        font-family: Poly;
        font-weight: 500;
        font-size: 40px;
    }

    p {
        font-family: Montserrat;
        font-size: 19px;
    }

    @media (max-width: 700px) {
        h1 {
            font-size: 26px;
        }
        p {
            font-size: 18px;
        }
    }
`;
