import { AxiosError } from 'axios';
import jwt from 'jsonwebtoken';
import { makeAutoObservable } from 'mobx';
import authApi from '../api/authApi';
import { User, UserRole } from '../models/User';
import { ServerError } from '../shared/model/ServerError';
import Transport from '../shared/transport';
import { TokenUtils } from '../shared/utils/TokenUtils';
import { isAzureAuthEnabled } from '../Environment';
import { AccountInfo } from '@azure/msal-browser';
import { msalInstance } from '../authConfig';
import userApi from '../api/userApi';
import { Principal } from '../models/Principal';

export class AuthStore {
    error: string = '';
    isLoggedIn: boolean = false;
    loggedInUser: User | null = null;
    isLoading: boolean = false;

    constructor() {
        makeAutoObservable(this);

        if (!isAzureAuthEnabled()) {
            this.isLoggedIn = !TokenUtils.isJwtTokenExpired();
            const token = TokenUtils.getJwtTokenString();
            if (token && this.isLoggedIn) {
                this.createUserFromToken(token);
                this.setError('');
            }
        }
    }

    async login(email: string, password: string): Promise<boolean> {
        try {
            const response = await authApi.auth.login(email, password);
            const authorizationHeader = response.headers.authorization;
            const token = authorizationHeader.substring('Bearer '.length, authorizationHeader.length);
            if (token) {
                TokenUtils.saveJwtToken(token);
                this.createUserFromToken(token);
                if (this.loggedInUser?.role === UserRole[UserRole.ADMIN]) {
                    this.isLoggedIn = true;
                    this.error = '';
                } else {
                    this.isLoggedIn = false;
                    this.error = 'Access Denied';
                }
            }
        } catch (error) {
            this.isLoggedIn = false;
            if (Transport.isAxiosError(error)) {
                const serverError = error as AxiosError<ServerError>;
                if (serverError && serverError.response) {
                    this.error = serverError.response.data.message;
                }
            }
        }
        return this.isLoggedIn;
    }

    async getUserData(account: AccountInfo | null): Promise<void> {
        this.setIsLoading(true);
        if (account?.name && !this.isLoggedIn) {
            try {
                const res = await userApi.internalPrincipal.getCurrent();
                this.createUserFromAccount(account, res.data);
                this.setIsLoggedIn(true);
            } catch (error) {
                console.error(error);
            } finally {
                this.setIsLoading(false);
            }
        } else {
            this.setIsLoading(false);
        }
    }

    logout = (callback?: VoidFunction) => {
        TokenUtils.removeJwtToken();
        this.isLoggedIn = false;
        this.loggedInUser = null;

        if (isAzureAuthEnabled()) {
            msalInstance.logoutRedirect();
        } else {
            if (callback) callback();
        }
    };

    createUserFromToken(token: string) {
        const decodedToken = jwt.decode(token);
        if (decodedToken !== null) {
            this.loggedInUser = new User();
            this.loggedInUser.id = decodedToken['id'];
            this.loggedInUser.username = decodedToken['userName'];
            this.loggedInUser.role = decodedToken['role'];
        }
    }

    createUserFromAccount(accountInfo: AccountInfo, principal: Principal) {
        if (principal != null && this.isValidRoles(principal.role)) {
            this.loggedInUser = new User();
            this.loggedInUser.id = principal.userId;
            this.loggedInUser.username = accountInfo.name || principal.username;
            this.loggedInUser.role = principal.role;
        } else {
            this.isLoggedIn = false;
            this.loggedInUser = null;
            msalInstance.logoutRedirect();
        }
    }

    isValidRoles(role: string) {
        return role === UserRole.ADMIN;
    }

    setError(value: string) {
        this.error = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setIsLoggedIn(value: boolean) {
        this.isLoggedIn = value;
    }
}
