import styled from 'styled-components';

export const Container = styled.div`
    background-color: white;
    width: 100%;
    position: sticky;
    border-bottom: 1px solid #ebebeb;
    z-index: 1;
    top: 0;
    height: 51px;
`;

export const Nav = styled.ul`
    overflow: hidden;
    font-size: 0;
    position: relative;
    padding: 0;
    user-select: none;

    li {
        display: inline-block;
        height: 51px;
        font-size: 16px;
        text-align: center;
        line-height: 51px;
        color: #202834;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        a {
            display: inline-block;
            height: 100%;
            text-decoration: none;
            margin-left: 20px;

            &:link,
            &:visited,
            &:active {
                color: #202834;
            }

            &.active {
                color: #21467e;
                border-bottom: 4px solid #21467e;
            }
        }
    }
`;
