import styled from 'styled-components';

export const LayoutContainer = styled.div<{ marginTop: number }>`
    padding-top: ${(p) => p.marginTop}px;

    .with-sidebar {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        height: calc(100vh - ${(p) => p.marginTop}px);
        overflow: hidden;
    }

    .with-sidebar > :first-child {
        flex-grow: 1;
    }

    .with-sidebar > :last-child {
        flex-basis: 0;
        flex-grow: 999;
        min-width: 50%;
        overflow: auto;
        height: 100%;
    }
`;

export const ContentLayout = styled.div<{ marginTop: number }>`
    /* padding-top: ${(p) => p.marginTop}px;

    width: 95%;
    margin: 0px auto 1rem;
    background-size: cover;
    text-align: center; */
`;
