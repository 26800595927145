import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponseTransformer } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { JsogService } from 'jsog-typescript';
import { JsogObject } from 'jsog-typescript/dist/model/JsogObject';
import { Class } from 'jsog-typescript/dist/support/Class';
import { TokenUtils } from '../utils/TokenUtils';

const jsog = new JsogService();

export class Transport {
    fetch: AxiosInstance;

    constructor(baseURL: string = '') {
        this.fetch = axios.create({ baseURL });
    }

    protected onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        return config;
    };

    protected onRequestError = async (error: AxiosError): Promise<AxiosError> => {
        return Promise.reject(error);
    };

    protected setupInterceptors() {
        this.fetch.interceptors.request.use(this.onRequest, this.onRequestError);
    }

    public get<T>(url: string, classObject?: Class<object>): AxiosPromise<T> {
        return this.fetch.get<T>(url, Transport.transformResponseConfig(classObject));
    }

    public getFile<T = any>(url: string): AxiosPromise<T> {
        return this.fetch.get<T>(url, {
            method: 'GET',
            responseType: 'blob'
        });
    }

    public post<T = any>(
        url: string,
        data?: any,
        classObject?: Class<object>,
        config?: AxiosRequestConfig | undefined
    ): AxiosPromise<T> {
        return this.fetch.post<T>(url, data, Transport.transformResponseConfig(classObject, config));
    }

    public postAsMultipart<T = any>(url: string, data?: any, classObject?: Class<object>): AxiosPromise<T> {
        const config = {
            ...Transport.transformResponseConfig(classObject),
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        return this.fetch.post<T>(url, data, config);
    }

    public put<T = any>(url: string, data?: any, classObject?: Class<object>): AxiosPromise<T> {
        return this.fetch.put<T>(url, data, Transport.transformResponseConfig(classObject));
    }

    public putAsMultipart<T = any>(url: string, data?: any, classObject?: Class<object>): AxiosPromise<T> {
        const config = {
            ...Transport.transformResponseConfig(classObject),
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        return this.fetch.put<T>(url, data, config);
    }

    public patch<T = any>(url: string, data?: any, classObject?: Class<object>): AxiosPromise<T> {
        return this.fetch.patch<T>(url, data, Transport.transformResponseConfig(classObject));
    }

    public del<T = any>(url: string, classObject?: Class<object>): AxiosPromise<T> {
        return this.fetch.delete<T>(url, Transport.transformResponseConfig(classObject));
    }

    public setJwtToken(jwtToken: string) {
        if (jwtToken) {
            this.fetch.defaults.headers.common.Authorization = Transport.createBearerAuthorization(jwtToken);
            TokenUtils.saveJwtToken(jwtToken);
        } else {
            delete this.fetch.defaults.headers.common.Authorization;
            TokenUtils.removeJwtToken();
        }
    }

    public static isTokenExpiredError(errorResponse: any): boolean {
        let isExpiredError = false;
        if (errorResponse === StatusCodes.UNAUTHORIZED) {
            isExpiredError = TokenUtils.isTokenExpired();
        }
        return isExpiredError;
    }

    public handleResponse(onFulfilled?: (error: any) => any, onRejected?: (error: any) => any) {
        this.fetch.interceptors.response.use(onFulfilled, onRejected);
    }

    public static serialize<T>(object: T): JsogObject & T {
        return jsog.serialize(object);
    }

    public getBaseUrl(): string | undefined {
        return this.fetch.defaults.baseURL;
    }

    public static isAxiosError(error: any): error is AxiosError {
        return (error as AxiosError).isAxiosError !== undefined;
    }

    private static transformResponseConfig(
        classObject?: Class<object>,
        config?: AxiosRequestConfig | undefined
    ): AxiosRequestConfig | undefined {
        let requestConfig: AxiosRequestConfig | undefined;
        let transformResponse: AxiosResponseTransformer | AxiosResponseTransformer[] | undefined;
        const transformer = axios.defaults.transformResponse as AxiosResponseTransformer[];

        if (classObject !== undefined) {
            transformResponse = transformer.concat((data: any) => {
                return Transport.transformData(data, classObject);
            });
        }

        if (classObject !== undefined && config !== undefined) {
            requestConfig = {
                ...config,
                transformResponse
            };
        } else if (classObject !== undefined) {
            requestConfig = {
                transformResponse
            };
        } else if (config !== undefined) {
            requestConfig = config;
        }

        return requestConfig;
    }

    private static transformData(data: JsogObject | JsogObject[], classObject: Class<object>): object {
        const result = jsog.deserialize(data, classObject);
        if (Array.isArray(result)) {
            return result as object[];
        }
        return result as object;
    }

    private static createBearerAuthorization(token: string): string {
        return `Bearer ${token}`;
    }
}
