import styled from 'styled-components';
import { BrandingHexColors } from '../GlobalStyle';

export const Button = styled.button<{ primary?: boolean }>`
    padding: 0.5rem 1rem;
    border-radius: 3px;
    background-color: ${(p) => (p.primary ? BrandingHexColors.PARADIGM_GREEN : BrandingHexColors.BUILDERS_BLUE)};
    background-size: cover;
    border: 1px solid transparent;
    font-family: Montserrat;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
    cursor: pointer;
    transition: 0.1s linear;
    outline: none;
    &:hover {
        opacity: 0.75;
        transform: translate3d(0, -2px, 0);
    }
    &:disabled {
        background-color: rgba(138, 138, 138, 0.2);
        color: #8a8a8a;
        box-shadow: none;
        cursor: not-allowed;
        &:hover {
            opacity: 1;
            transform: none;
            background-color: rgba(138, 138, 138, 0.2);
            color: #8a8a8a;
        }
    }
`;

export const SecondaryButton = styled.button`
    padding: 0.5rem 1rem;
    border-radius: 3px;
    background-color: #f7f7f7;
    background-size: cover;
    font-family: Montserrat;
    font-size: 14px;
    color: #202834;
    text-decoration: none solid rgb(32, 40, 52);
    text-align: center;
    border: 1px solid transparent;
    transition: 0.1s linear;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    &:hover {
        background: #ebebeb;
    }
    &:disabled {
        background-color: rgba(138, 138, 138, 0.2);
        color: #8a8a8a;
        box-shadow: none;
        cursor: not-allowed;
        &:hover {
            opacity: 1;
            transform: none;
            background-color: rgba(138, 138, 138, 0.2);
            color: #8a8a8a;
        }
    }
`;
