import * as yup from 'yup';
import { Formik, Form } from 'formik';
import TextFormField from '../shared/styleguide/Form/TextFormField';
import Button from '../shared/styleguide/Button';
import { useStores } from '../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { useNavigate, useLocation } from 'react-router';
import ErrorMessage from '../shared/styleguide/ErrorMessage';

const validationSchema = yup.object({
    email: yup.string().email().required(),
    password: yup.string().required()
});

interface LoginFormValues {
    email: string;
    password: string;
}

function LoginForm() {
    const initialValues: LoginFormValues = { email: '', password: '' };
    const { authStore } = useStores();
    const navigate = useNavigate();
    const location = useLocation();

    const state = location.state as { from: Location };
    const from = state ? state.from.pathname : '/';

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);

                const isLoggedIn = await authStore.login(data.email, data.password);

                if (isLoggedIn) {
                    navigate(from, { replace: true });
                }

                setSubmitting(false);
            }}
        >
            {({ isSubmitting }) => (
                <Form>
                    {authStore.error && <ErrorMessage>{authStore.error}</ErrorMessage>}
                    <TextFormField placeholder="Email" name="email" type="email" autoComplete="on" label="Email" />
                    <TextFormField
                        placeholder="Password"
                        name="password"
                        type="password"
                        autoComplete="on"
                        label="Password"
                    />
                    <Button primary disabled={isSubmitting} type="submit">
                        Login
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

export default observer(LoginForm);
