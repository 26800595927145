import { makeAutoObservable } from 'mobx';
import clientCredentialApi from '../api/clientCredentialAPI';
import { Claim } from '../models/Claim';
import { ClientCredential } from '../models/ClientCredential';

export class ClientCredentialStore {
    isLoading: boolean = true;
    searchTerm: string = '';
    clientCredentials: ClientCredential[] = [];
    clientCredential: ClientCredential = new ClientCredential();
    claims: Claim[] = [];
    claim: Claim = new Claim();

    constructor() {
        makeAutoObservable(this);
    }

    fetchAllClientCredentials = async () => {
        try {
            this.setIsLoading(true);
            const clientCredentials = await clientCredentialApi.clientCredential.getAll();
            this.setClientCredentials(clientCredentials.data.items);
        } catch (error) {
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    getClientCredentialById = async (credentialId: string) => {
        this.clientCredentials.length < 1 && (await this.fetchAllClientCredentials());
        const cred = this.clientCredentials.find((x) => x.id === credentialId);
        cred && this.setClientCredential(cred);
    };

    get filteredClientCredentials() {
        return [];
    }

    fetchAllClaims = async () => {
        try {
            this.setIsLoading(true);
            const claims = await clientCredentialApi.claims.getAll();

            if (claims.data) {
                this.setClaims(claims.data.items);
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    setClientCredentials(value: ClientCredential[]) {
        this.clientCredentials = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setSearchTerm(value: string) {
        this.searchTerm = value;
    }

    setClientCredential(value: ClientCredential) {
        this.clientCredential = value;
    }

    setClaims(value: Claim[]) {
        this.claims = value;
    }

    setClaim(value: Claim) {
        this.claim = value;
    }
}
