import { isNull, orderBy } from 'lodash';
import { makeAutoObservable } from 'mobx';
import userApi from '../api/userApi';
import { Company } from '../models/Company';
import { User } from '../models/User';
import { Builder, getDisplayNames } from '../models/Builder';
import builderApi from '../api/builderApi';

export class UserStore {
    isLoading: boolean = true;
    searchTerm: string = '';
    users: User[] = [];
    user: User = new User();
    companies: Company[] = [];
    builders: Builder[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    fetchAll = async () => {
        try {
            this.setIsLoading(true);
            const r = await userApi.users.getAll();
            this.setUsers(r.data);
        } catch (error) {
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    fetchById = async (userId: string) => {
        try {
            this.setIsLoading(true);
            const r = await userApi.users.getById(userId);
            this.setUser(r.data);
        } catch (error) {
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    update = async (user: User) => {
        try {
            this.setIsLoading(true);
            isNull(user.securityGroups) && (user.securityGroups = []);
            const r = await userApi.users.update(user);
            this.setUser(r.data);
        } catch (error) {
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    get filteredUsers() {
        return this.users.filter(
            (user) =>
                user.username.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                user.company?.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
    }

    fetchAllCompanies = async () => {
        try {
            this.setIsLoading(true);
            const r = await userApi.companies.getAll();
            this.setCompanies(orderBy(r.data, [(company) => company.name.toLowerCase()]));
        } catch (error) {
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    };

    fetchAllBuilders = async () => {
        try {
            const builders = (await builderApi.builders.getAll()).data.content;
            getDisplayNames(builders);
            this.setBuilders(orderBy(builders, [(builder) => builder.name.toLowerCase()]));
        } catch (error) {
            console.error(error);
        }
    };

    setUsers(value: User[]) {
        this.users = value;
    }

    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    setSearchTerm(value: string) {
        this.searchTerm = value;
    }

    setUser(value: User) {
        this.user = value;
    }

    setCompanies(value: Company[]) {
        this.companies = value;
    }

    setBuilders(value: Builder[]) {
        this.builders = value;
    }
}
