import {
    AuthenticationResult,
    Configuration,
    EventMessage,
    EventType,
    PublicClientApplication,
    RedirectRequest
} from '@azure/msal-browser';
import {
    getAzureAuthBrandName,
    getAzureAuthClientId,
    getAzureAuthDomain,
    getAzureAuthInstance,
    getAzureAuthSignUpSignInPolicy
} from './Environment';

const appSettings = {
    AzureAdB2C: {
        Instance: getAzureAuthInstance(),
        ClientId: getAzureAuthClientId(),
        Domain: getAzureAuthDomain(),
        SignUpSignInPolicy: getAzureAuthSignUpSignInPolicy()
    },
    MSAL: {
        RedirectUri: '/',
        PostLogoutRedirectUri: '/user',
        CacheLocation: 'sessionStorage',
        StoreAuthStateInCookie: false
    }
};

// Set this here to be referenced in b2cPolicies
const authorityBaseAddress = appSettings.AzureAdB2C.Instance + '/' + appSettings.AzureAdB2C.Domain;

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: appSettings.AzureAdB2C.SignUpSignInPolicy
    },
    authorities: {
        signUpSignIn: {
            authority: authorityBaseAddress + '/' + appSettings.AzureAdB2C.SignUpSignInPolicy
        }
    },
    authorityDomain: appSettings.AzureAdB2C.Domain,
    authorityBaseAddress: authorityBaseAddress
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: appSettings.AzureAdB2C.ClientId, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain, b2cPolicies.authorityBaseAddress], // Mark your B2C tenant's domain as trusted.
        redirectUri: appSettings.MSAL.RedirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: appSettings.MSAL.PostLogoutRedirectUri, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: appSettings.MSAL.CacheLocation, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: appSettings.MSAL.StoreAuthStateInCookie // Set this to "true" if you are having issues on IE11 or Edge
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const msalLoginRequest: RedirectRequest = {
    scopes: [],
    extraQueryParameters: {
        otpUsage: 'never',
        brandUsage: getAzureAuthBrandName()
    }
};

const msalInstanceApplication = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstanceApplication.getActiveAccount() && msalInstanceApplication.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstanceApplication.setActiveAccount(msalInstanceApplication.getAllAccounts()[0]);
}

export const isSuccessEvent = (event: EventMessage): boolean => {
    return (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
    );
};

msalInstanceApplication.addEventCallback((event: EventMessage): void => {
    if (isSuccessEvent(event) && event.payload) {
        const authenticationResult = event.payload as AuthenticationResult;
        const account = authenticationResult.account;
        msalInstance.setActiveAccount(account);
    }
});

msalInstanceApplication.initialize();

export const msalInstance = msalInstanceApplication;
