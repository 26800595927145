import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Spinner from '../../shared/styleguide/Spinner';
import TabNavigation from '../../shared/styleguide/TabNavigation';

const ClientCredentialList = React.lazy(() => import('./ClientCredentialList'));
const ClientCredentialForm = React.lazy(() => import('./ClientCredentialForm'));
const ClaimList = React.lazy(() => import('./ClaimList'));

const menuItems = [
    { name: 'client-credentials', path: '/client-credentials/credentials', label: 'Client Credentials' },
    { name: 'claims', path: '/client-credentials/claims', label: 'Scopes' }
];

const ClientCredentialsRoutes = () => {
    return (
        <React.Fragment>
            <TabNavigation items={menuItems} />
            <Suspense fallback={<Spinner />}>
                <Routes>
                    <Route path="/*" element={<Navigate replace to="/client-credentials/credentials" />} />
                    <Route path={'/credentials/*'} element={<ClientCredentialList />} />
                    <Route path={'/credentials/add'} element={<ClientCredentialForm />} />
                    <Route path={'/credentials/:credentialId'} element={<ClientCredentialForm />} />
                    <React.Fragment>
                        <Route path={'/claims'} element={<ClaimList />} />
                    </React.Fragment>
                </Routes>
            </Suspense>
        </React.Fragment>
    );
};

export default ClientCredentialsRoutes;
