import { JsonProperty } from 'jsog-typescript';
import { BaseAuditModel } from '../shared/model/base/BaseAuditModel';
import { Company } from './Company';

export enum UserRole {
    ADMIN = 'ADMIN',
    USER = 'USER',
    ARCHITECT = 'ARCHITECT',
    TEAM_LEADER = 'TEAM_LEADER',
    SUPER_USER = 'SUPER_USER',
    BUILDER = 'BUILDER',
    CATALOG_MANAGER = 'CATALOG_MANAGER'
}

export class User extends BaseAuditModel {
    username: string;
    role: string;
    @JsonProperty(Company)
    company: Company;
    externalBuilderId?: string;
    firstName: string;
    lastName: string;
    active: boolean;
    termsAndConditionsAcceptanceDate: Date | null;
    securityGroups: any[];
}
