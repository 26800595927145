import styled from 'styled-components';

export const ErrorMessageContainer = styled.div`
    height: auto;
    border-radius: 3px;
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    border: 1px solid;
    text-align: left;
    padding: 10px;
    margin-bottom: 30px;
`;
