import React from 'react';
import { AuthStore } from '../stores/AuthStore';
import { ClientCredentialStore } from '../stores/ClientCredentialStore';
import { RoleStore } from '../stores/RoleStore';
import { UserStore } from '../stores/UserStore';

export const storesContext = React.createContext({
    userStore: new UserStore(),
    authStore: new AuthStore(),
    clientCredentialStore: new ClientCredentialStore(),
    roleStore: new RoleStore()
});
