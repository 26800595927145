import { AxiosPromise } from 'axios';
import qs from 'qs';
import { PaginatedContent } from '../models/Page';
import { builderApiProvider } from './providers/builderApiProvider';

const builders = {
    getAll: (): AxiosPromise<PaginatedContent> => {
        return builderApiProvider.post<PaginatedContent>(`/builders/list`, undefined, undefined, {
            paramsSerializer: () => {
                return qs.stringify({ unpaged: true });
            }
        });
    }
};

const builderApi = {
    builders
};

export default builderApi;
