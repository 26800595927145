import { AxiosPromise } from 'axios';
import { Claim } from '../models/Claim';
import { ClientCredential } from '../models/ClientCredential';
import { PagingList } from '../models/PagingList';
import { userApiProvider } from './providers/userApiProvider';

const clientCredential = {
    create: (input: any): AxiosPromise<ClientCredential> => {
        return userApiProvider.post(`/clientcredentials`, input, ClientCredential);
    },
    getAll: (): AxiosPromise<PagingList> => {
        return userApiProvider.get(`/clientcredentials/all`, ClientCredential);
    },
    remove: (input: ClientCredential): AxiosPromise<ClientCredential> => {
        return userApiProvider.del(`/clientcredentials/${input.id}`);
    },
    update: (input: ClientCredential): AxiosPromise<ClientCredential> => {
        return userApiProvider.put(`/clientcredentials/${input.id}`, input, ClientCredential);
    }
};

const claims = {
    create: (input: Claim): AxiosPromise<Claim> => {
        return userApiProvider.post(`/clientcredentials/scopes`, input, Claim);
    },
    getAll: (): AxiosPromise<PagingList> => {
        return userApiProvider.get(`/clientcredentials/scopes/all`, Claim);
    },
    remove: (input: Claim): AxiosPromise<Claim> => {
        return userApiProvider.del(`/clientcredentials/scopes/${input.id}`);
    },
    update: (input: Claim): AxiosPromise<Claim> => {
        return userApiProvider.put(`/clientcredentials/scopes`, input, Claim);
    }
};

const clientCredentialApi = {
    clientCredential,
    claims
};

export default clientCredentialApi;
