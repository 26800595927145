import { Outlet } from 'react-router-dom';
import Header from '../Header';
import SideNavigation from '../SideNavigation';
import { ContentLayout, LayoutContainer } from './Layout.style';

export const HEADER_HEIGHT = 60;

export function Layout() {
    return (
        <div>
            <Header height={HEADER_HEIGHT} />
            <LayoutContainer marginTop={HEADER_HEIGHT}>
                <div className="with-sidebar">
                    <SideNavigation />
                    <ContentLayout marginTop={HEADER_HEIGHT}>
                        <Outlet />
                    </ContentLayout>
                </div>
            </LayoutContainer>
        </div>
    );
}
