import styled, { keyframes } from 'styled-components';

const DEFAULT_SIZE = 60;

const spin = keyframes`
    from{transform: rotate(0deg);}
    to{transform: rotate(360deg);}
`;

export const Container = styled.div<{ size?: number }>`
    position: relative;
    top: auto;
    left: auto;
    opacity: 0.8;
    border: 5px solid #e0e0e0;
    border-top-color: #8a8a8a;
    width: ${(props) => (props.size ? props.size : DEFAULT_SIZE)}px;
    height: ${(props) => (props.size ? props.size : DEFAULT_SIZE)}px;
    border-radius: 50%;
    animation: ${spin} 0.7s linear infinite;
`;
