import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useStores } from '../hooks/useStores';
import { isAzureAuthEnabled } from '../Environment';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { AuthenticationResult } from '@azure/msal-browser';
import { msalLoginRequest } from '../authConfig';

const RequireAuth = () => {
    const { authStore } = useStores();
    const location = useLocation();
    const { instance } = useMsal();

    function handleResponse(response: AuthenticationResult | null) {
        if (isAzureAuthEnabled() && !response) {
            const currentAccounts = instance.getAllAccounts();
            console.log(currentAccounts);
            if (currentAccounts.length === 0) {
                instance.loginRedirect(msalLoginRequest);
            }
        }
    }

    if (!isAzureAuthEnabled() && !authStore.isLoggedIn) {
        return <Navigate to="/login" state={{ from: location }} />;
    } else {
        instance.handleRedirectPromise().then(handleResponse);
    }

    if (isAzureAuthEnabled()) {
        return (
            <AuthenticatedTemplate>
                <Outlet />
            </AuthenticatedTemplate>
        );
    }

    return <Outlet />;
};

export default RequireAuth;
