import { AxiosPromise } from 'axios';
import { Company } from '../models/Company';
import { Roles } from '../models/Roles';
import { User } from '../models/User';
import { userApiProvider } from './providers/userApiProvider';
import { Principal } from '../models/Principal';

const users = {
    getAll: (): AxiosPromise<User[]> => {
        return userApiProvider.get(`/users`, User);
    },
    getById: (userId: string): AxiosPromise<User> => {
        return userApiProvider.get(`/users/${userId}`, User);
    },
    update: (user: User): AxiosPromise<User> => {
        return userApiProvider.post(`/users/${user.id}`, user, User);
    }
};

const companies = {
    getAll: (): AxiosPromise<Company[]> => {
        return userApiProvider.get(`/companies`, Company);
    }
};

export interface RoleInput {
    name: string;
    description: string | null;
    externalAccessAllowed: boolean;
    hierarchyOrder: number;
}
const roles = {
    getAll: (): AxiosPromise<Roles[]> => {
        return userApiProvider.get(`/roles`, Roles);
    },
    getRole: (roleId: string): AxiosPromise<Roles> => {
        return userApiProvider.get(`/roles/${roleId}`, Roles);
    },
    create: (role: RoleInput): AxiosPromise<Roles> => {
        return userApiProvider.put(`/roles`, role, Roles);
    },
    update: (roleId: string, role: RoleInput): AxiosPromise<Roles> => {
        return userApiProvider.post(`/roles/${roleId}`, role, Roles);
    },
    delete: (roleId: string): AxiosPromise<Roles> => {
        return userApiProvider.del(`/roles/${roleId}`);
    }
};

const userDetails = {
    getByUserName: (username: string): AxiosPromise<User> => {
        return userApiProvider.post(`/user-details/dto`, { username });
    }
};

const internalPrincipal = {
    getCurrent: (): AxiosPromise<Principal> => {
        return userApiProvider.get(`/internal-principal/current`);
    }
};

const userApi = {
    users,
    companies,
    roles,
    userDetails,
    internalPrincipal
};

export default userApi;
