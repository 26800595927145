import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Container } from './SideNavigation.style';

interface SideNavItem {
    name: string;
    label: string;
    path?: string;
    items?: SideNavItem[];
    external?: boolean;
}

const items: SideNavItem[] = [
    { name: 'user', label: 'Users', path: '/user' },
    { name: 'client-credentials', label: 'Client Credentials', path: '/client-credentials' },
    { name: 'roles-management', path: '/roles-management', label: 'Roles Management' }
];

interface SideNavigationItemProps {
    item: SideNavItem;
    paddingLeft?: number;
    paddingLeftMultiplier?: number;
}

const SideNavigationItem: React.FC<SideNavigationItemProps> = ({
    item,
    paddingLeft = 10,
    paddingLeftMultiplier = 0
}) => {
    const [collapsed, setCollapsed] = useState(true);

    function toggleCollapse() {
        setCollapsed((prevValue) => !prevValue);
    }

    const location = useLocation();

    const isActive = (path?: string) => {
        if (!path) return false;
        return location.pathname.indexOf(path) > -1;
    };

    const navigate = useNavigate();

    const onItemClick = (event: any, item: SideNavItem) => {
        event.stopPropagation();

        if (Array.isArray(item.items)) {
            toggleCollapse();
        }

        if (!item.path) return;

        if (item.external) {
            window.open(item.path);
        } else {
            navigate(item.path, { replace: true });
        }
    };

    return (
        <React.Fragment key={item.name}>
            <div
                className={`side-nav__list-item ${isActive(item.path) ? 'active' : ''}`}
                onClick={(e) => onItemClick(e, item)}
            >
                <div className="side-nav__list-item-text" style={{ paddingLeft: paddingLeftMultiplier * paddingLeft }}>
                    {item.label}
                </div>
            </div>
            <div style={{ display: collapsed ? 'none' : 'block' }}>
                {Array.isArray(item.items) ? (
                    <div className="side-nav__list">
                        {item.items?.map((subItem, index) => (
                            <SideNavigationItem
                                key={`${subItem.name}__${index}`}
                                item={subItem}
                                paddingLeftMultiplier={paddingLeftMultiplier + 1}
                                paddingLeft={paddingLeft}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    );
};

const SideNavigation: React.FC = () => {
    return (
        <Container>
            <div className="side-nav__list">
                {items.map((item, index) => (
                    <SideNavigationItem key={`${item.name}__${index}`} item={item} paddingLeftMultiplier={1} />
                ))}
            </div>
        </Container>
    );
};

export default observer(SideNavigation);
