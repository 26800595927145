import { BaseAuditModel } from '../shared/model/base/BaseAuditModel';
import { Claim } from './Claim';

export class ClientCredential extends BaseAuditModel {
    name: string;
    builderOmniCustomer: boolean;
    claims: Claim[];
    claimIds?: string[];
    clientKey?: string;
    clientSecret?: string;
    eventCallbackUrl?: string;
}
