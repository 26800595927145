import React from 'react';
import { ErrorMessageContainer } from './ErrorMessage.style';

type Props = {
    children?: React.ReactNode;
};

export function ErrorMessage(props: Props) {
    return <ErrorMessageContainer>{props.children}</ErrorMessageContainer>;
}
