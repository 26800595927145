import { AxiosRequestConfig } from 'axios';
import { getAzureAuthBuilderProjectScope, getBuilderProjectAPI, isAzureAuthEnabled } from '../../Environment';
import { TokenUtils } from '../../shared/utils/TokenUtils';
import { Transport } from '../../shared/transport/Transport';
import { requestWithAuthorization, requestWithAuthorizationFromToken } from '../../utils/AuthUtils';

class BuilderApiProvider extends Transport {
    constructor() {
        super(getBuilderProjectAPI());
        this.setupInterceptors();
    }

    override onRequest = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        if (isAzureAuthEnabled()) {
            return requestWithAuthorization(config, [getAzureAuthBuilderProjectScope()]);
        }
        return requestWithAuthorizationFromToken(config, TokenUtils.getJwtTokenString());
    };
}

export const builderApiProvider = new BuilderApiProvider();
