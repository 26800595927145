import { isEmpty, isNull } from 'lodash';

export interface Builder {
    name: string;
    id: string;
    companyId: string;
    integrationId: string;
    displayName: string;
}

export function getDisplayNames(builders: Builder[]) {
    return (
        !isEmpty(builders) &&
        builders.map((builder) => {
            return (builder.displayName = isNull(builder.integrationId)
                ? `${builder.name}`
                : `${builder.name} - ${builder.integrationId}`);
        })
    );
}
