import { rgba } from 'polished';
import styled from 'styled-components';
import { BrandingHexColors } from '../GlobalStyle';

export const Container = styled.div`
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 3px;
    z-index: 2;

    .side-nav__list-item {
        font-size: 16px;
        font-family: Montserrat;
        display: block;
        color: #202834;
        cursor: pointer;
        transition: all 0.1s ease-in-out;

        &.active {
            background-color: ${rgba(BrandingHexColors.BUILDERS_BLUE, 0.75)};
            color: white;
        }

        &:hover:not(.active) {
            background-color: ${rgba(BrandingHexColors.BUILDERS_BLUE, 0.2)};
        }
    }

    .side-nav__list-item-text {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 18px;
    }

    .side-nav__list__collapsable {
        max-height: 0;
        opacity: 0;
        transition: all 0.2s ease-in-out;

        .open {
            max-height: auto;
            opacity: 1;
        }
    }
`;
