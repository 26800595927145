import { Outlet } from 'react-router-dom';
import { LoginLayoutContainer } from './LoginLayout.style';
import Logo from '../assets/images/HomeConfigureLogo.png';

export function LoginLayout() {
    return (
        <LoginLayoutContainer>
            <img src={Logo} alt="" />
            <div className="login-form__container">
                <Outlet />
            </div>
        </LoginLayoutContainer>
    );
}
